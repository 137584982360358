import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveTecnico({ commit }, formData) {
      try {
        return await api.post('/tecnico', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } catch (error) {
        throw error
      }
    },

    async updateTecnico({ commit }, formData) {
      try {
        console.log(formData)
        return await api.post(
          `/tecnico/${formData.get('id')}/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      } catch (error) {
        throw error
      }
    },

    async getTecnicos({ commit }) {
      try {
        const response = await api.get('/tecnico')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTecnicosByEscola({ commit }) {
      try {
        const response = await api.get('/tecnico-escola')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTecnico({ commit }, tecnicoId) {
      try {
        const response = await api.get(`/tecnico/${tecnicoId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
