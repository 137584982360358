import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveModalidade({ commit }, modalidade) {
      try {
        return await api.post('/modalidade', modalidade)
      } catch (error) {
        throw error
      }
    },

    async getModalidades({ commit }) {
      try {
        const response = await api.get('/modalidade')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getModalidade({ commit }, modalidadeId) {
      try {
        const response = await api.get(`/modalidade/${modalidadeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCategoriasHasModalidade({ commit }, modalidadeId) {
      try {
        const response = await api.get(`/modalidade/${modalidadeId}/categorias`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateModalidade({ commit }, data) {
      try {
        console.log(data)
        return await api.put(`/modalidade/${data.modalidadeId}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
