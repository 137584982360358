import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveAtleta({ commit }, formData) {
      try {
        return await api.post('/atleta', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } catch (error) {
        throw error
      }
    },

    async updateAtleta({ commit }, formData) {
      try {
        console.log(formData)
        return await api.post(
          `/atleta/${formData.get('id')}/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      } catch (error) {
        throw error
      }
    },

    async getAtletas({ commit }) {
      try {
        const response = await api.get('/atleta')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getAtletasByEscola({ commit }) {
      try {
        const response = await api.get('/atleta-escola')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getAtleta({ commit }, atletaId) {
      try {
        const response = await api.get(`/atleta/${atletaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
