import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Administration',
    path: PATHS.ADM,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/administration/Administration.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'AdministrationEscolas',
        path: PATHS.ADM_ESCOLAS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/escolas/Escolas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
      {
        name: 'AdministrationDadosEscolas',
        path: PATHS.ADM_ESCOLA_DADOS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/escolas/DadosEscola.vue'
          ),
      },
      {
        name: 'AdministrationModalidades',
        path: PATHS.ADM_MODALIDADES,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/modalidades/Modalidades.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.03.001',
        },
      },
      {
        name: 'AdministrationCategorias',
        path: PATHS.ADM_CATEGORIAS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/categorias/Categorias.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.04.001',
        },
      },
      {
        name: 'AdministrationTecnicos',
        path: PATHS.ADM_TECNICOS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/tecnicos/Tecnicos.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.06.001',
        },
      },
      {
        name: 'AdministrationEquipeDeApoio',
        path: PATHS.ADM_EQUIPE_APOIO,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/equipedeapoio/EquipeDeApoio.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.06.001',
        },
      },
      {
        name: 'AdministrationAtletas',
        path: PATHS.ADM_ATLETAS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/atletas/Atletas.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.05.001',
        },
      },
      {
        name: 'AdministrationTimes',
        path: PATHS.ADM_TIMES,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/times/Times.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.07.001',
        },
      },
      {
        name: 'AdministrationPerfis',
        path: PATHS.ADM_PERFIS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/perfil/Perfil.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.08.001',
        },
      },
    ],
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === 'Auth') {
    next({ name: 'Home' })
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn && !localStorage.getItem('token')) {
      next({ name: 'Auth' })
    } else {
      if (to.meta.permission !== undefined) {
        if (!store.getters.getModuleUserByCode(to.meta.permission)) {
          next({ name: 'Unauthorized' })
        }
      }
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
