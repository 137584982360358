import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveEquipe({ commit }, equipe) {
      try {
        return await api.post('/equipe-apoio', equipe)
      } catch (error) {
        throw error
      }
    },

    async getEquipes({ commit }) {
      try {
        const response = await api.get('/equipe-apoio')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEquipeDeApoioByEscola({ commit }) {
      try {
        const response = await api.get('/equipe-apoio-escola')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEquipe({ commit }, equipeId) {
      try {
        const response = await api.get(`/equipe-apoio/${equipeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateEquipe({ commit }, equipe) {
      try {
        return await api.put(`/equipe-apoio/${equipe.id}`, equipe)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
