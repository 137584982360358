import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveEscola({ commit }, escola) {
      try {
        return await api.post('/escola', escola)
      } catch (error) {
        throw error
      }
    },

    async getEscolas({ commit }) {
      try {
        const response = await api.get('/escola')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEscola({ commit }, escolaId) {
      try {
        const response = await api.get(`/escola/${escolaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateEscola({ commit }, escola) {
      try {
        return await api.put(`/escola/${escola.id}`, escola)
      } catch (error) {
        throw error
      }
    },

    async updatePassword({ commit }, data) {
      try {
        return await api.put(`/escola/${data.escolaId}/password`, data)
      } catch (error) {
        throw error
      }
    },

    async getUserModules({ commit }, userId) {
      try {
        const response = await api.get(`users/${userId}/modules`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUserModules({ commit }, data) {
      try {
        return await api.put(`users/${data.userId}/modules`, data.modules)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
