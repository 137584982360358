import api from '@/services/api'

export default {
  state: {
    isLoggedIn: !!localStorage.getItem('token'),
    user: {
      id: 0,
      name: '',
      modules: null,
    },
  },

  mutations: {
    AUTH(state, user) {
      state.isLoggedIn = true
      state.user = {
        id: user.id,
        name: user.name,
        modules: user.modules,
      }
      console.log(state.user)
    },

    LOGOUT(state) {
      state.isLoggedIn = false
      state.user = {
        id: 0,
        name: '',
        modules: null,
      }
    },
  },

  actions: {
    async auth({ commit }, credentials) {
      try {
        const response = await api.post('/auth/login', {
          cnpj: credentials.cnpj,
          password: credentials.password,
        })

        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''

        const user = {
          id: data && data.escola ? data.escola.id : 0,
          name: data && data.escola ? data.escola.descricao : '',
          modules: data && data.modules ? data.modules : null,
        }
        console.log(user)
        localStorage.setItem('token', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    async passwordRecovery({ commit }, email) {
      try {
        const response = await api.post('/auth/password/recovery', {
          email,
        })

        return response || null
      } catch (error) {
        throw error
      }
    },

    async newPassword({ commit }, data) {
      try {
        const response = await api.post('/auth/password/new', data)

        return response || null
      } catch (error) {
        throw error
      }
    },

    logout: ({ commit }) => {
      localStorage.removeItem('token')
      commit('LOGOUT')
    },
  },

  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
    getModuleUserByCode: (state) => (code) => {
      if (state.user.modules !== null) {
        return state.user.modules.find((module) => module.code === code)
      }
      return false
    },
  },
}
