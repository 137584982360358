const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_ESCOLAS = `${ADM}/escolas`
const ADM_ESCOLA_DADOS = `${ADM}/dados-escola`
const ADM_MODALIDADES = `${ADM}/modalidades`
const ADM_CATEGORIAS = `${ADM}/categorias`
const ADM_TECNICOS = `${ADM}/tecnicos`
const ADM_EQUIPE_APOIO = `${ADM}/equipe-apoio`
const ADM_TIMES = `${ADM}/times`
const ADM_ATLETAS = `${ADM}/atletas`
const ADM_PERFIS = `${ADM}/perfis`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_ESCOLAS,
  ADM_ESCOLA_DADOS,
  ADM_MODALIDADES,
  ADM_PERFIS,
  ADM_CATEGORIAS,
  ADM_TECNICOS,
  ADM_TIMES,
  ADM_ATLETAS,
  ADM_EQUIPE_APOIO,
  UNAUTHORIZED,
}
