import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveTime({ commit }, time) {
      try {
        return await api.post('/time', time)
      } catch (error) {
        throw error
      }
    },

    async getTimes({ commit }) {
      try {
        const response = await api.get('/time')
        console.log(response.data)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTimesByEscola({ commit }) {
      try {
        const response = await api.get('/time-escola')
        console.log(response.data)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTime({ commit }, timeId) {
      try {
        const response = await api.get(`/time/${timeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTecnicosByTime({ commit }, timeId) {
      try {
        const response = await api.get(`/time/${timeId}/tecnicos`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getAtletasByTime({ commit }, timeId) {
      try {
        const response = await api.get(`/time/${timeId}/atletas`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateTime({ commit }, data) {
      try {
        return await api.put(`/time/${data.id}`, data)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
