import auth from './modules/auth/auth'
import layouts from './modules/layout/layouts'
import escolas from './modules/escola/escolas'
import modalidades from './modules/modalidade/modalidades'
import categorias from './modules/categoria/categorias'
import perfis from './modules/perfis/perfis'
import tecnicos from './modules/tecnico/tecnicos'
import modules from './modules/module/modules'
import commons from './modules/common/commons'
import atletas from './modules/atleta/atletas'
import times from './modules/time/times'
import equipedeapoio from './modules/equipedeapoio/equipedeapoio'

export default {
  auth,
  layouts,
  escolas,
  modalidades,
  categorias,
  tecnicos,
  perfis,
  modules,
  commons,
  atletas,
  times,
  equipedeapoio,
}
