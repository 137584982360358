import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveCategoria({ commit }, categoria) {
      try {
        return await api.post('/categoria', categoria)
      } catch (error) {
        throw error
      }
    },

    async getCategorias({ commit }) {
      try {
        const response = await api.get('/categoria')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCategoria({ commit }, categoriaId) {
      try {
        const response = await api.get(`/categoria/${categoriaId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getCategoriasByModalidades({ commit }, unidadeId) {
      try {
        const response = await api.get(`/setores/byUnidade/${unidadeId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateCategoria({ commit }, categoria) {
      try {
        return await api.put(`/categoria/${categoria.id}`, categoria.categoria)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
